.Share{
    $count: 4;
    $gap: 8px*$count;
    $icons: 16px * $count;
    $padding: 24px + 4px;
    $height: 34px;
    $width: $icons + $gap + $padding;
    @apply relative;

    &-btn{
        @apply flex items-center gap-2;
    }

    &-btnText{
        @apply
            text-blue-light
            font-bold
            inline-block
            relative
            z-0;
    }

    &-arrow{
        @apply
            bg-no-repeat
            bg-bottom
            bg-blue-light
            inline-block
            rounded-full
            transition
            z-20 
            relative;
        @include size($height);
        background-image: url("#{$dist}/share-arrow.svg");
    }

    &-list{
        @apply
            bg-blue-light
            flex
            items-center
            gap-1
            absolute
            right-2
            z-10
            top-1/2
            -translate-y-1/2
            rounded-l-full
            origin-right
            scale-x-0
            transition
            pr-0;
        direction: rtl;
        @include size($width, $height);

        li{ @apply shrink-0 }
        
        img{ @apply block w-2 h-2; }
    }

    //open

    &.is-open {
        .Share{
            &-arrow{ @apply -rotate-90; } 
            &-list{ @apply scale-100 pr-3 pl-sm; }
        }
    }
}