
.BranchDetails {
    @apply 
        max-w-3xl
        mx-auto;

    &-info {
        @apply
            border-t
            border-gray-line;

        &--title {
            @apply mt-4;
        }
    }

    &-imageAddress {
        @apply
            mt-8;
    }

    &-image {
        @apply
            bg-white
            rounded-tr-lg
            rounded-bl-lg
            border
            border-black;
        box-shadow: 8px 8px 0 $black;
    }

    &-back {
        @apply
            relative
            flex
            flex-row
            items-start
            py-3;

        &:before {
            @apply 
                block
                w-[8px]
                h-[13px]
                mr-1;

            content: url($dist + '/arrow-left--branch.svg');
        }
    }
}

.BranchHours,
.BranchFeatures {
    @apply
        mt-9;
}

.BranchHours {
    @apply 
        grid 
        auto-rows-auto
        bg-gray-light
        bg-opacity-50;

        grid-template-columns: repeat(5, minmax(min-content, max-content));
        // grid-template-columns: minmax(min-content, max-content) repeat(4, minmax(min-content, 1fr));

    &-day {
        @apply
            flex
            items-center
            text-left
            px-2;
    }

    &-lobby {
        @apply
            pl-2
            py-3
            bg-gray-medium
            bg-opacity-10
            text-left;

        + .BranchHours-lobby {
            @apply
                pl-0
                pr-2
                text-left;
                font-size:13px;
        }

        &--label {
            @apply
                bg-gray-medium
                bg-opacity-10;
        }
    }

    &-driveThru {
        @apply
            pl-2
            py-3;

        + .BranchHours-driveThru {
            @apply
                pl-0
                pr-2;
                font-size:13px;
        }
    }

    &-day {
        &.last {
            @apply
                self-start;
        }
    }

    &-lobby, &-driveThru {
        font-size:13px;

        &--label {
            @apply
                pl-2
                pt-3
                pb-0
                font-bold
                text-18;
        }
    }

    &-lobby, &-driveThru, &-day {
        &.last {
            @apply
                pt-1;
        }
    }
}

.BranchHoursNoDriveThru {
    grid-template-columns: repeat(3, minmax(min-content, max-content));
}

.BranchFeatures {
    @apply
        pl-7;

    &-feature {
        @apply
            flex
            flex-row
            items-center
            text-18
            leading-8
            text-black
            mb-[16px];

        &:before {
            content: url($dist + '/cross--gray.svg');
            @apply
                block
                w-[23px]
                h-[22px]
                mr-2;
        }

        &--checked {
            &:before {
                content: url($dist + '/checkmark--gray.svg');
            }
        }
    }
}