.UpEv{
    --gutter: 0px;
    --per-page: 4;

    &-list{
        @apply 
            grid 
            overflow-x-scroll
            py-4;
        cursor: grab;
        grid-gap: calc(var(--gutter));
        grid-template-columns: 2.5% repeat(var(--total), calc(100% * (4 / 16) - var(--gutter) * 2)) 2.5%;
        // grid-template-columns: 2.5% repeat(var(--total), calc(#{100*(4 / 16)}% - var(--gutter) * 2)) 2.5%;
        grid-template-rows: minmax(480px, 1fr);
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;
        scroll-snap-type: x proximity;
        scroll-padding-left: 2.5%;

        &::before,
        &::after {
            content: '';
        }

        &::-webkit-scrollbar{  @apply hidden; }

        &.is-down{
            scroll-snap-type: none;
            li{
                transform: scale(1.02);
            }
            
        }
    }
    
    &-item{
        scroll-snap-align: start;
        @apply 
            relative 
            pr-3 
            flex 
            flex-col 
            gap-2;

        &:not(li:last-child)::after{
            content: '';
            @apply absolute;
            top:0;
            bottom:0;
            width:1px;
            right:var(--gutter);
        }
    }
    
    &-item:first-child{
        a{
            @apply opacity-100;
        }
    }


    

    .Tag{
        @apply
            -ml-4
            rounded-l-none
            rounded-r-none;
    }

    .Tag.is-first{
        @apply
            ml-0
            rounded-l-full;
    }

    .Tag.is-last{
        @apply rounded-r-full
    }
    

    &-box{
        @apply
            pt-0
            px-0
            overflow-hidden
            shadow-box-small
            border-2
            shadow-gray-line
            h-full;
    }

    &-info{
        @apply
            px-3 
            py-4 
            flex 
            gap-3
            items-start;
    }

    @screen lg{
        --per-page: 3;
        &-list{
            grid-template-columns: 2.5% repeat(var(--total), calc(100% * (5 / 16) - var(--gutter) * 2)) 2.5%;
        }
    }

    @screen md{
        --per-page: 2;
        &-list{
            grid-template-columns: 2.5% repeat(var(--total), calc(100% * (7 / 16) - var(--gutter) * 2)) 2.5%;
        }
    }

    @screen sm{
        --per-page: 1;
        &-list{
            grid-template-columns: 2.5% repeat(var(--total), calc(100% * (13 / 16) - var(--gutter) * 2)) 2.5%;
        }
    }
}

.NavEvents.UpEv {
    .UpEv {
        &-list {
            grid-template-columns: 2.5% 1fr 2.5%;
        } 
        &-box {
            @apply
                max-w-md;
                // max-h-[375px]

            > img {
                @apply
                    max-h-[225px]
                    rounded-b-none;
            }
        }
    }
}

.DropCTA.EventsJoin {
    @apply
        min-w-[250px]
        rounded-tr-lg;
}