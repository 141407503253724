.ProductHero {
    // --ProductHeroHeight: calc(100vh - (var(--navHeight)*2));
    // height: var(--ProductHeroHeight);
    min-height: 715px;

    .breadcrumb {
        @apply
            rounded-full
            bg-gray-light
            flex
            flex-row
            items-center
            justify-evenly
            py-1
            px-2
            mb-4
            uppercase;

            font-size: 13px;
            width: fit-content;

        span.product-title {
            @apply
                text-black
                font-semibold;
        }

        span.separator {
            @apply
                text-gray-line
                px-1;
        }
    }

    &-image {
        @apply
            absolute
            top-0
            left-0
            w-1/2;
        max-height: var(--ProductHeroHeight);
        min-height: 800px;

        img {
            @apply
                object-cover
                rounded-br-2xl;
            min-height:840px;
            @screen md {
                min-height:0px;
            }
        }
    }

    &-intro {
        @apply
            w-7/16
            pt-10
            pb-5
            pr-6
            pl-2;
    }

    @screen md {
        @apply
            min-h-0
            h-auto;

        &-image {
            @apply
                relative
                w-full
                h-auto
                min-h-0;

            img {
                @apply
                    rounded-tl-2xl;
            }
        }

        &-intro {
            @apply
                py-10
                w-full;
        }
    }
}

.Donkey{

    position: absolute;
    bottom: 60px;
    left: 30px;

    &-pin{
        @apply
            bg-white 
            border 
            border-black 
            rounded-lg 
            py-1
            px-2 
            text-gray-dark
            font-bold
            text-16
            whitespace-nowrap
            flex
            items-center
            -translate-y-1/2
            mt-2;
        
        &::before{
            content: '';
            @apply
                mr-sm
                inline-block
                w-3
                h-3
                bg-no-repeat;
            background-image: url("#{$dist}/checkmark--green.svg");
        }

        /** @screen lg{  @apply hidden; } **/
    }
}

.Subnav {
    @apply
        relative;

    ul {
        @apply
            w-[95%]
            mr-auto
            ml-auto
            flex
            gap-2;

        li {
            @apply
                border
                border-b-0
                border-gray-line
                rounded-tl-md
                rounded-tr-md
                flex-1
                py-1.5;

            h4 {
                @apply
                    text-center
                    text-18;

                a {
                    @apply
                        uppercase
                        font-normal
                        text-blue-dark;
                }
            }

            &.active {
                @apply
                    bg-gray-light
                    border-gray-light;

                a {
                    @apply
                        text-black
                        font-black;
                }
            }
        }
    }

    // &::before {
    //     content: '';
    //     @apply
    //         bottom-4
    //         absolute
    //         border-b-2
    //         border-blue;
    //     left: var(--pos);
    //     width: var(--width);
    //     transition: all .3s $easeInOutQuad;
    // }

    @screen md {
        ul {
            @apply
                gap-1
                justify-between;
        }

        a {
            @apply
                text-12;
        }
    }

    @screen sm {
        ul {
            @apply
                hidden;
        }
    }
}

.ProductOverview {
    @apply
        w-full
        flex
        flex-wrap
        justify-evenly
        gap-y-5
        p-10
        bg-gray-light;

    h2 {

    }

    &-keyStats {
        @apply
            w-5/16
            bg-white
            rounded-tr-xl
            rounded-bl-xl
            px-4
            py-6
            flex
            flex-col
            gap-6;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        min-width:496px;

        h3 {
            @apply
                text-[62px];
        }

        .Rate {
            &-separator {
                @apply
                    w-[1px]
                    bg-gray-lighter
                    origin-center
                    rotate-[30deg]
                    h-[58px]
                    mx-3;

                content: ' ';
            }

            &-number {
                @apply
                    text-56
                    font-semibold;

                .Rate-unit {
                    &:first-child {
                        @apply
                            ml-0
                            relative
                            top-[22px];
                    }
                }
            }
        }
    }

    &-keyFeatures {
        @apply
            w-10/16
            grid
            grid-cols-2
            gap-5
            items-start
            justify-center
            py-5
            pl-12
            pr-0;
    }

    img {
        @apply
            h-9
            mb-3;
    }

    p {
        margin-top:0px;
    }

    @screen xxl {
        @apply 
            pb-0;
    }

    @screen lg {

        &-keyFeatures {
            @apply
                gap-3;
        }

        &-keyStats {
            @apply
                px-4;
        }
    }

    @screen md {
        &-keyStats {
            @apply
                w-full
                grid
                grid-cols-1
                rounded-tl-xl
                rounded-br-xl
                rounded-bl-none
                rounded-tr-none;
        }

        &-keyFeatures {
            @apply
                w-full
                pl-0;
        }
    }

    @screen sm {
        &-keyStats {
            @apply
                grid-cols-1;
        }

        &-keyFeatures {
            @apply
                grid-cols-1;
        }
    }
}

.HowSteps {
    @apply
        w-8/16
        flex
        flex-col
        gap-3;
    counter-set: steps;

    &-step {
        @apply
            flex
            flex-col
            gap-2
            bg-gray-light
            rounded-md
            p-4;

        h4 {
            @apply
                text-[24px];
        }

        p {
            @apply
                text-gray-dark
                mt-0;
        }

        &::before {
            @apply
                text-white
                bg-green
                rounded-sm
                block
                text-center
                w-3
                h-3
                font-bold
                text-[16px];
            counter-increment: steps;
            content: counter(steps);
        }
    }

    @screen md {
        @apply
            w-full
            max-w-2xl;
    }
}

.ComparisonTable {
    &-table {
        @apply
            grid;
        grid-template-columns: repeat(var(--cols), minmax(0, 1fr));
    }

    &-header {
        @apply
            bg-gray-light
            flex
            flex-col
            justify-between
            items-start
            rounded-md
            p-2
            pt-3
            mb-3
            mx-2;

        p,
        .btn {
            @apply
                mt-1;
        }

        p {
            @apply
                flex-1;
        }

        .btn {
            @apply
                py-3
                bg-gray-light
                border-blue-dark
                text-blue-dark;
        }

        &--current {
            @apply
                bg-black
                text-white
                mb-4;

            h4 {
                @apply
                    text-white;
            }

            .btn {
                @apply
                    bg-green
                    text-white;
            }
        }
    }

    &-cell {
        @apply
            p-2
            relative;

        &:not(.ComparisonTable-cell--rowHeader) {
            @apply
                text-center;
        }

        // &::after{
        //     @apply
        //         border-b-2
        //         border-gray-line
        //         block
        //         absolute
        //         -left-3
        //         -right-3
        //         bottom-0;
        //     content: '';
        // }

        &.odd {
            @apply
                bg-gray-light;
        }

        &--rowHeader {
            @apply
                text-black
                font-bold;
        }

        img {
            @apply
                stroke-green;
        }
    }

    &-sectionHeader {
        @apply
            w-full
            uppercase
            text-[13px]
            text-blue-dark
            col-span-full
            pl-2
            my-2;

        &:not(.first) {
            @apply
                mt-4;
        }
    }

    // Responsive
    @screen md {
        &-table {
            @apply
                gap-0
                border-collapse
                relative;
            // column-gap: 2px;
            // row-gap: 2px;
            column-gap:-1px;
            grid-template-columns:  repeat(2, minmax(0, 1fr));
        }

        &-heade r {
            @apply
                mx-2;
        }

        &-sectionHeader {
            @apply
                bg-white;
            grid-column: 1 / span 2;
        }

        &-cell::after {
            @apply
                left-0
                right-0
                hidden;
        }

        &-cell {
           @apply
            bg-gray-light;
        }

        &-cell--rowHeader {
            @apply
                col-span-2
                text-center
                font-bold
                bg-white
                text-black
                border-b
                border-black
        }

        &-cell--current {
            @apply
                bg-white;
        }
    }

    @screen sm {
        &-header {
            @apply
                mx-1
                px-1;

            p {
                @apply
                    hidden;
            }

            h4 {
                @apply
                    text-18;
            }

            .btn {
                @apply
                    whitespace-nowrap;
            }

            &--current {
                @apply
                    shadow-none;
            }
        }
    }
}

.Learn-More-Guides {
    @apply
        my-12;
}

.Why-Join {
    @apply
        mt-8;

    .Reason {
        @apply
            rounded
            border-transparent
            pt-4;

        img {
            height: 90px;
        }
    }
}

.financialInvestmentLogo {
    float:left;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.financialInvestmentImage {
    max-width: 450px;
    float: right;
    margin-left:40px;
}

.financialInvestmentHeadshot {
    float:left;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 25px;
}

.FooterFootnotesArticle {
    @apply
        flex
        flex-col;

    @screen lg {
        @apply
            flex-row
            w-full;
    }

    li {
        @apply
            flex
            flex-row
            items-baseline
            justify-start
            mb-5
            text-black;

        &:before {
            @apply
                text-black
                mr-1;
            content: attr(data-footnote) ". ";
        }

        p {
            @apply
                text-black
                font-bold
                text-16;
        }

        strong {
            @apply text-black
        }

        a{
            @apply underline text-black
        }
    }
}