table{
    
}

th{
    @apply
        border-b-2
        border-black
        pb-2
        font-semibold
        text-black;
}

td{
    @apply
        py-2
        border-b
        border-gray-line;
}