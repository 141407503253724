@import 'swiper/css';

.swiper-slide{
    height: auto;
    width: initial;
    max-width: 66%;

    img{
        @apply h-full object-cover;
    }
}