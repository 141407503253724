.OpenAccountHero{
    @apply
        relative
        flex
        items-center;
    margin-top: calc(-1 * var(--navHeight));
    padding-top: var(--navHeight);
    min-height: 900px;

    &::before{
        content: '';
        @apply
            w-1/2
            absolute
            top-0
            right-0
            bottom-0
            bg-cover
            rounded-bl-2xl;
        z-index: -1;
        background-image: var(--bg);

        @screen retina{
            background-image: var(--bg2x);
        }
    }

    //responsive
    @screen md{
        &::before{
            @apply
                w-full
                aspect-video
                rounded-l-full;
            left:2.5%;
            top:calc(var(--navHeight));
            background-image: var(--m);
        }
    }
}

.OrgSearch{
    @apply
        bg-blue
        text-white
        px-6;
    border-radius: calc(5.22222rem - .88888rem) 0 calc(5.22222rem - .88888rem) 0;
    
    &-input{
        @apply
            mb-4
            mt-6
            rounded-full
            w-full
            text-white
            py-2
            pl-6
            pr-1;
        background:#008DA1 url("#{$dist}/search--blue.svg") 16px center no-repeat;
        
        &::placeholder{
            @apply text-white transition-all;
        }

        &:focus::placeholder{
            @apply text-opacity-50
        }
    }

    ul{
        @apply
        h-[50vh]
        max-h-[800px]
        min-h-[400px]
        overflow-scroll
    }

    li{
        @apply
            py-2
            border-b
            border-[#008DA1];

        mark{
            @apply font-bold bg-transparent text-white;
        }
    }
}


.Reason{
    @apply
        border-black 
        border-2 
        rounded 
        rounded-tr-none 
        rounded-bl-none 
        flex 
        flex-col 
        items-center
        text-center 
        pb-4
        px-4
        pt-4
        gap-5
        bg-white
        flex-1;
    min-width: 250px;

    img{
        height: 80px;
    }

    @screen lg{
        @apply
            p-3
    }
}