.LoginModal {
    @apply 
        relative 
        max-w-md 
        mx-auto 
        mt-15
        p-5
        bg-white 
        rounded-md 
        border 
        border-black 
        shadow-black 
        shadow-box-small;
}