.SearchModal{
    @apply
        w-9/16
        mx-auto
        mt-15;
    border-radius: 52px;

    @screen lg {
        @apply
            w-14/16;
    }
}