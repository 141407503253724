.Four04Search{
    .Search-results{
        @apply 
            absolute
            bg-white
            shadow-box-small
            border-black
            border
            mt-1
            shadow-black
            rounded-md
            w-full;
    }
}