.AccountFinder {
    @apply
        w-full;

    &-messages{
        @apply
            overflow-auto
            pb-5
            pt-4
            border-t
            border-gray-line;
        height: 330px;
    }

    &-list{
        @apply
            flex
            flex-col
            items-start
            justify-start
            gap-2;
    }

    .AccountFinder-typing{
        @apply
            block
            w-5;
        padding: 0px 20px;

        //faux typing indicator
        &::before{
            content: url("#{$dist}/three-dots.svg");
            @apply
                block
                w-full
                object-fill;
        }
    }

    &-message-container {
        @apply
            flex
            flex-row;

        .AccountFinder-message-source {
            @apply
                rounded-full
                bg-black
                self-start
                mt-1;
            padding: 8px 6px;
        }

        .AccountFinder-message-source-text {
            @apply
                text-16
                font-semibold
                mb-1;
        }

        .AccountFinder-message-text {
            @apply
                rounded-sm
                text-white
                bg-blue
                pl-2
                pr-3
                py-1;

            //animate the message bubbles in
            transform: scale(0);
            transform-origin: top left;
            animation: bubble 300ms linear 0s 1 normal forwards;

            a {
                @apply text-white underline;
            }
        }
    }

    .AccountFinder-message-container:nth-child(even){
        @apply
            self-start
            relative;

        .AccountFinder-message-source-text-customer {
            display: none;
        }

        .AccountFinder-message-text {
            @apply
                bg-blue
                relative
                rounded-tl-none;
        }

        //caret
        // &::before{
        //     content: '';
        //     background-image: inline-svg('<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.626953 8.51366C0.626953 8.51366 1.13947 0.636963 6.70539 0.636963C6.70539 6.74587 14.3755 8.5136 14.3755 8.5136L0.626953 8.51366Z" fill="#00AEC7"/> </svg>');
        //     @apply
        //         absolute
        //         leading-none
        //         -translate-y-full;
        //     height: 8px;
        //     width: 15px;
        //     top: 0;
        //     left:-1px;
        // }
    }

    .AccountFinder-message-container:nth-child(odd){
        @apply
            self-end;

        // hide avatar for user input messages
        img {
            display: none;
        }

        .AccountFinder-message-source-text-pathways {
            display: none;
        }

        .AccountFinder-message-text {
            @apply
                bg-green
                self-end
                rounded-tr-none;
            transform-origin: top right;
        }

        // caret
        // &::before{
        //     content: '';
        //     background-image: inline-svg('<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.625 8.01415C14.625 8.01415 14.1125 0.137451 8.54656 0.137451C8.54656 6.24636 0.876503 8.01409 0.876503 8.01409L14.625 8.01415Z" fill="#78BE20"/> </svg>');
        //     @apply
        //         absolute
        //         leading-none
        //         -translate-y-full;
        //     height: 8px;
        //     width: 15px;
        //     top: 0;
        //     right:-1px;
        // }
    }

    &-answers{
        @apply
            bg-white
            rounded-full
            py-2
            px-2
            gap-1
            flex;
            overflow: overlay;

        @screen sm {
            @apply  overflow-x-scroll;
        }
    }

    &-answer{
        @apply
            text-black
            border
            border-opacity-20
            text-16
            font-normal
            py-2
            px-2;
            background: linear-gradient(0deg, rgba(0, 174, 199, 0.1), rgba(0, 174, 199, 0.1)), #FFFFFF;
            border: 1px solid #00AEC7;

        &:hover,
        &:focus{
            box-shadow: 2px 2px 0px $gray-medium !important;
        }
    }
}

@keyframes bubble {
    from{ opacity: 0; transform:scale(0); }
    to{ opacity: 1; transform:scale(1);}
}