.Accordion{
    @apply
        flex-1 
        min-h-[16rem] 
        space-y-4;

    &-item{
        @apply
            rounded 
            bg-white
            border
            border-black;
    }

    .is-expanded{
        @apply shadow-box-small shadow-black;
    }

    &-btn{
        @apply
            rounded-none
            bg-transparent
            flex 
            w-full 
            items-center 
            justify-between 
            p-3 
            text-24 
            font-bold
            text-black;

        &:focus,
        &:hover{
            @apply
                transform-none
                shadow-none;
        }
    }

    &-plus{
        background-image: url("#{$dist}/plus.svg");
        @apply
            block;
        text-indent: -9999px;
        //not using rem because on smaller screens it changes
        @include size(24px);
    }
}