.CommunityHero{
    @apply
        flex
        justify-end;
        

    &-visual img{
        @apply
            w-full
            h-full
            object-cover
            rounded-tl-2xl
            rounded-br-2xl;

    }

    //Responsive
    @screen md{
        @apply
            flex-col-reverse;
        
        &-visual img{
            @apply aspect-square
        }
    }
}