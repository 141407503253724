.FooterRouting {
    @apply
        flex
        flex-row
        justify-between
        items-center
        bg-blue-dark
        w-full
        py-3
        px-5;

    @screen lg {
        @apply
            px-[2.5%]
            mt-7
            flex-col
            items-start;
    }
}

.FooterRoutingNumber {
    @apply
        text-16
        text-white
        p-0
        m-0;

    &--text {
        @apply
            mr-3
            font-bold;
    }

    @screen lg {
        @apply
            flex
            flex-row
            justify-between
            w-full
            mb-2;
    }
}

.FooterTelephoneBanking {
    @apply
        text-16
        text-white
        p-0
        m-0
        flex
        flex-row
        items-center;

    &--icon {
        @apply
            flex
            flex-row
            items-center
            mr-3;

        &:before {
            content: url($dist + '/icons/phone-footer.svg');
            @apply
                block
                w-[17px]
                h-[17px]
                mr-1;
        }
    }

    @screen lg {
        @apply
            flex
            flex-row
            justify-between
            w-full;
    }
}

.Footer {
    @apply
        w-full
        bg-black;

    &-main,
    &-meta,
    &-newsletterPrivacy {
        @apply
            flex
            flex-row
            py-6
            px-9;

        @screen lg {
            @apply
                flex-col
                container
                px-0
                py-6;
        }
    }

    &-meta {
        @apply
            flex
            flex-col
            items-center
            justify-center
            pt-2;
    }

    &-newsletterPrivacy {
        @apply
            pt-4
            pb-1
    }

    &-siteNav {
        @apply
            flex
            flex-row
            w-full
            justify-around;

        @screen lg {
            @apply
                flex-col;
        }

        li {
            > p, > h6 {
                @apply
                    text-gray-medium
                    text-[20px]
                    font-normal;
            }

            > ul {
                @apply
                    flex
                    flex-col
                    mt-3;

                li {
                    @apply  mb-2;

                    a {
                        @apply
                            text-18
                            text-white;
                    }
                }
            }
        }
    }

    &-newsletterPrivacy {
        @apply
            justify-between
            items-center;

        @screen lg {
            @apply
                items-start;
        }
    }
}

.FooterLogo {
    @apply
        block
        bg-no-repeat
        mb-5
        h-[77px];

    aspect-ratio: calc( 245/77 );
    background: url("#{$dist}/logo-footer.svg") center/100% no-repeat;
}

.FooterOpenAccount {
    @apply
        px-8
        font-bold
        btn
        md:w-min;
}

.FooterNewsletter {
    // more to come
    @screen lg {
        @apply
            mb-2;
    }

    &-fields {
        @apply
            flex
            flex-row
            items-center
            rounded-full
            bg-white
            bg-opacity-50;

    }

    &-emailInput {
        @apply
            bg-transparent
            py-[16px]
            px-[30px]
            outline-none
            border-none
            text-white
            font-bold;

        &:focus {

        }

        &::placeholder {
            @apply
                text-white;
        }
    }

    &-submit {
        @apply
            rounded-full
            bg-black
            text-white
            py-[12px]
            px-[25px]
            mr-[4px]
            transition-colors;

        &:hover {
            @apply
                bg-green
                text-black;
        }
    }
}

.FooterPrivacyNav {
    @apply
        flex
        flex-row
        mx-auto;

    li {
        @apply
            mr-5;

        &:last-child {
            @apply mr-0;
        }

        a {
            @apply
                text-white;
        }
    }

    @screen lg {
        @apply
            flex-col
            mt-2;

        li {
            @apply
                mr-0
                mb-2;
        }
    }

}

.FooterFootnotes {
    @apply
        flex
        flex-col;

    @screen lg {
        @apply
            flex-row
            w-full;
    }

    li {
        @apply
            flex
            flex-row
            items-baseline
            justify-start
            mb-5
            text-white;

        &:before {
            @apply
                text-white
                mr-1;
            content: attr(data-footnote) ". ";
        }

        p {
            @apply
                text-white
                font-bold
                text-16;
        }

        strong {
            @apply text-white
        }

        a{
            @apply underline text-white
        }
    }
}

.FooterHousingOpportunities {
    @apply
        flex
        flex-row
        max-h-[95px]
        items-center;

    li {
        @apply  mr-5;

        &:last-child {
            @apply  mr-0;
        }
    }
}