.CareersHero{
    &-header{  
        @apply
            rounded-tr-xl
            rounded-bl-xl
            overflow-hidden
            relative;

        &::before{
            content: '';
            @apply
                absolute
                inset-0
                z-10;
            background: linear-gradient(265.94deg, rgba(0, 0, 0, 0.39) 2.94%, rgba(0, 0, 0, 0) 69.01%);
        }
        h1{
            @apply 
                absolute 
                z-10 
                top-1/2
                -translate-y-1/2
                right-4
                w-7/16
                text-white;
        }
    }
    &-visual{
        @apply
            w-full;
    }

    //responsive
    @screen md{
        
        &-visual{
            @apply object-cover;
            object-position:25% center;
            height: 750px;
        }

        h1{
            @apply w-14/16 left-1/2 bottom-4 translate-y-0 top-auto -translate-x-1/2 right-auto;
        }
    }
}



.Job{
    @apply
        rounded 
        border-2 
        border-black  
        px-2 
        pt-10 
        pb-2 
        flex 
        flex-col 
        justify-end
        text-gray-dark;
    width: calc(33% - 32px);

    &:hover{
        box-shadow: 2px 2px 0px $black;
        transform: translate(-2px, -2px);
    }

    //repsonsive
    @screen md{
        width: calc(50% - 16px);
    }
    @screen sm{
        width: 100%;
    }
}




/*************************************
* J O B detail view
*************************************/

.JobBar{
    @apply
        px-3
        py-1
        rounded-md
        border
        border-gray-line
        flex
        justify-between
        items-center;

    &-share{
        @apply
            inline-flex
            gap-sm
            mr-3;
    }
}

.JobDescription {
    &.prose {
        ul {
            @apply list-none;
            li {
                @apply
                    flex
                    flex-row
                    relative
                    items-start
                    pl-0
                    pb-4;

                &:before {
                    content: "\2022";

                    @apply
                        text-blue
                        block
                        mr-[12px];
                }
            }
        }
    }   
}