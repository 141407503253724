.LangSwitch{
    @apply
        fixed
        bottom-6
        right-8
        z-50;

    &-list{
        @apply 
            hidden
            bg-black
            text-white
            absolute
            bottom-0
            w-full
            pb-6
            z-0
            rounded-md;

        a{
            @apply
                text-white
                font-bold
                py-1
                px-2
                block
                pl-6;
        }
    }

    &-btn{
        @apply 
            bg-black
            text-white
            py-1
            px-2
            font-bold
            flex
            justify-center
            items-center
            rounded-full
            gap-1
            z-10
            relative;

        &::after{
            content: inline-svg('<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.499969 1.65375L1.65372 0.499999L6.99997 5.84625L12.3462 0.5L13.5 1.65375L6.99997 8.15375L0.499969 1.65375Z" fill="#ffffff"/> </svg>');
        }
    }

    &.is-open &-list{
        @apply block;
    }
}