.Tabs{
    &-list{
        @apply 
            flex
            gap-4;

        /* @screen sm {
            @apply flex-col;
        } */
    }

    &-tab{
        @apply
            bg-transparent
            border
            border-gray-dark
            text-gray-dark
            text-16
            font-bold
            tracking-wider;

        &:hover{
            @apply
                shadow-none
                translate-x-0
                translate-y-0
                text-black
                border-black;
        }

        &.is-active{
            @apply
                border-blue
                bg-blue
                text-white;
        }
        &.is-active::after {
            @apply inline-block
            pt-1;
            content: url($dist + '/arrow-right-white-button.svg');
        }
    }
}