.Tag{
    @apply
        rounded-full
        py-sm
        px-3
        font-black
        uppercase
        text-[13px]
        tracking-widest
        flex
        gap-1
        items-center;
    background-color: darken($color: $gray-light, $amount: 4%);
    color: $black;
    // color: lighten($color: $gray-dark, $amount: 15%)
}