.SecurityGuides{
    @apply
        w-13/16 
        mx-auto 
        mt-10 
        grid
        gap-3 
        justify-center;
    grid-template-columns: repeat(var(--cols), minmax(200px, 500px) );

    .Guide:nth-child(4):last-child{
        grid-column-start: 2;
    }

    //responsive

    @screen lg{
        @apply w-full;
    }

    @screen md{
        grid-template-columns: repeat(2, minmax(200px, 500px) );
    }
    @screen sm{
        grid-template-columns: minmax(200px, 500px);
    }
}

.FraudResources {
    &-item {
        p + p {
            @apply  mt-0;
        }
    }
}