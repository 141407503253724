.About-Intro {
    @apply 
        flex
        flex-row;
    @screen md {
        @apply
            block;
    }
    .Hero-Text > p {
        @apply
            text-[36px]
            text-black
            font-black
            normal-case
            leading-[129.5%];

        strong {
            color: #00AEC7;
        }
    }
}
.About-Hero {
    max-height: 617px;
    max-width: 524px;

    @screen sm {
        @apply w-full;
    }
}

.What-Were-About {
    @apply
        rounded-2xl
        rounded-tr-none
        rounded-bl-none
        px-12
        py-10
        bg-black
        text-white;

    h3 {
        @apply
            text-white;
    }

    &>p {
        @apply
            text-[24px]
            text-white
            font-medium
            mt-0;
    }

    @screen md {
        @apply 
            px-5;
    }

    .about-overview {
        @apply
            columns-2
            text-white
            mt-4;

        p:not(:first-child) {
            @apply
                mt-3;
        }
        @screen md {
            @apply
                columns-1;
        }
    }
}

.Pledge{
    @apply
        rounded
        bg-gray-light
        flex
        flex-col
        items-center
        text-center
        px-6
        py-3
        gap-3;

    img{
        max-width: 80px;
        min-height: 100px;
    }

    @screen lg{
        @apply
            p-3
    }
}

.ValuesSection {
    @apply 
        flex
        flex-row;
    @screen md {
        @apply 
            block;
    }
}

.Values {
    @apply
        w-9/16
        mt-6;

    &-list {
        @apply
            grid
            grid-cols-2
            sm:grid-cols-1
            sm:gap-x-0
            gap-3
            gap-x-4;
    }

    &-item {
        @apply
            bg-gray-light
            rounded
            p-4
            flex
            flex-col
            relative
            items-center
            justify-start
            h-fit;

        &:nth-child(odd) {
            @apply
                -mt-6
                md:mt-0;
        }

        &-index {
            @apply
                bg-green
                text-white
                font-bold
                text-16
                tracking-widest
                rounded-full
                px-3
                py-1.5
                text-center;
        }

        img {
            @apply
                mt-3;
        }

        h3 {
            @apply
                text-center
                text-[32px]
                leading-8
                mt-3;
        }

        p {
            @apply
                text-gray-dark
                mt-3;
        }
    }
    @screen md {
        @apply 
            w-full
            px-1;
    }
}
