.HomeHero{
    @apply
        relative
        flex
        items-center;
    margin-top: calc(-1 * var(--navHeight));
    padding-top: var(--navHeight);
    min-height: 779px;

    h1 {
        @apply relative;

        .plant {
            @apply absolute bottom-0 right-0;

            width: 87px;
            height: 69px;
            background: url("#{$dist}/new-plant.svg");
        }
    }

    .HomeHeroOpenAccount {
        @apply bg-black text-white px-6 font-bold;
        width: 188px;
    }

    &::before{
        content: '';
        @apply
            absolute
            top-0
            right-0
            bottom-0
            bg-cover;
        z-index: -1;
        background-image: var(--bg);
        width: 36%;

        @screen retina{
            background-image: var(--bg2x);
        }

        @screen md{
            @apply w-5/16;
        }

        @media only screen and (max-width: 580px) {
            content: none;
        }
    }

    &-boxes{
    }

    @screen lg{

    }

    @screen md{
        @apply pb-4;

        .AuthBox{
            @apply rounded-bl-none
        }
    }

}

.AuthBox{
    @apply
        bg-white
        rounded-tr-2xl
        rounded-bl-2xl
        p-5
        border
        border-black
        relative;
    border: 8px solid #77be21;
    max-width: 392px;
    // box-shadow: 8px 8px 0 #77be21;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     inset: 0;
    //     border-radius: 50px;
    //     padding: 10px;
    //     border: 8px solid transparent;
    //     // border-image-source: linear-gradient(146.15deg, #78BE20 -0.19%, #00AEC7 102.24%);
    //     background: linear-gradient(0deg, #FFFFFF, #FFFFFF) border-box, linear-gradient(146.15deg, #78BE20 -0.19%, #00AEC7 102.24%) border-box;
    //     -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    //     -webkit-mask-composite: xor;
    //     mask-composite: exclude;
    // }
}

.AtmButton{
    @apply
        font-bold
        text-black
        rounded-[15px]
        p-3
        inline-flex
        items-center
        gap-2
        mb-2
        whitespace-nowrap
        bg-gray-light;
    &::after{
        content: url("#{$dist}/arrow-forward-white.svg");
        @apply
            bg-blue
            rounded-sm
            w-3
            h-3
            flex
            items-center
            justify-center;
    }
}

.HomeHowCanWeHelp {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    gap: 32px;

    background: linear-gradient(180deg, #FEFEFE 0%, #F0F5FD 100%);
    border-radius: 0px 94px;
    border: 4px solid #77be21;
    max-width: 808px;
}

.WhyPoints{
    @apply
        flex
        justify-around;

    &>div{
        @apply flex flex-col justify-end items-center gap-3;

        & > img {
            &.stage-life,
            &.digital-banking {
                height: 72px;
            }
            &.locations,
            &.rates {
                height: 72px;
            }
        }
    }

    @screen lg{
        &>div{
            @apply px-2 w-auto text-center;
        }
    }

    @screen md{
        @apply flex-wrap gap-6;

        &>div{
            @apply w-7/16;
        }
    }

    @screen sm{
        &>div{
            @apply w-full;
        }
    }
}

.FinancialQuestionsAnswered {
    p {
        @apply
         text-22
         text-gray-dark;
        line-height: 126%;
    }
}

.Digital{
    &-visuals{
        @apply
            relative
            px-10;

        &:before{
            content: '';
            @apply
                bg-blue
                rounded-xl
                rounded-bl-none
                rounded-tr-none
                absolute
                z-0
                bottom-0
                right-0
                left-0
                top-9
                text-center;
        }
        img{
            @apply
                relative
                z-10
                mx-auto;
        }
    }
}

.digitalBankingTitle {
    min-width: 720px;
    @screen md {
        min-width: 100%;
    }
}

.HomeCommEvent{
    @apply
        flex
        flex-col
        flex-1
        flex-nowrap
        gap-2
        p-2
        bg-gray-light
        rounded-md
        text-gray-dark;

    img{
        border-radius: 16px;
    }
    p {
        font-size: 16px;
        line-height: 130%;
    }
}

.homepageBanner a {
    color: $white;
    text-decoration: underline;
}

.homepageBanner img {
    margin: auto;
}

.topBanner {
    position: relative;
    z-index: 999;
}

.topBanner img {
    margin: auto;
}

.banner-white-override {
    background-color: $white;
    border-bottom: 2px solid $gray-light;
    border-top: 2px solid $gray-light;
}

.banner-white-override a {
    color: $blue-dark !important;
}

.banner-white-text {
    color: $black !important;
}

.banner-white-text a {
    color: $blue-dark !important;
}

.banner-white-text img {
    margin: auto;
}

.homepageBanner iframe {
    width: 100%;
    height: auto;
}