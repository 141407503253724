.Guide{
    @apply
        shadow-box-small
        shadow-gray-line
        rounded-md
        border
        border-gray-medium
        flex
        flex-col
        overflow-hidden
        transition-all;
    aspect-ratio: .598;
    max-width: 360px;
    min-width: 300px;

    &-visual{
        @apply
            relative
            flex-1;

        h4{
            @apply
                text-white
                absolute
                w-1/2
                text-[28px]
                z-10;
        }

        img{
            @apply
                absolute
                z-0
                object-contain
                bottom-0;
        }
    }

    &-body{
        @apply
            p-2
            text-center
            text-gray-dark;
    }

    &:hover{
        @apply shadow-none;
        transform: translate(8px, 8px);
    }
    
}