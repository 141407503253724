.NeedHelp {
    > h3 {
        @apply
            text-black 
            pb-[81px];
    }

    > a {
        @apply
            text-gray-dark
            my-0
            py-0;    
    }

    &-list {
        @apply 
            flex 
            flex-row
            gap-[98px]
            mt-[86px];

        @screen md {
            @apply flex-col;
        }

        li {
            @apply 
                flex 
                flex-col 
                items-center 
                justify-center;
        }
    }

    &-label {
        @apply
            font-bold 
            pt-3
            pb-[4px]
            text-black;
    }
}