.Event{
    @apply
        flex
        items-start
        text-gray-dark
        py-9
        border-b
        border-gray-line;

    &:last-child{ @apply border-0; }
    
    &-dateBox{
        @apply
            bg-green-medium
            text-white
            text-center
            rounded-tr
            rounded-bl
            inline-block
            p-2
            w-10;

        h4,h5{@apply text-white}

        &+&::before{
            content: "";
            @apply
                h-8
                border-l-2
                border-green
                absolute
                top-1/2
                -translate-y-1/2;
        }
    }

    &-image{
        @apply
            rounded-tr-md
            rounded-bl-md;
    }

    &-location{
        @apply
            uppercase
            leading-none
            text-[13px]
            font-bold
            text-gray-dark
            tracking-widest
    }

    //responsive
    @screen md{
        @apply flex-wrap gap-y-4;

        &-dateBox + &-dateBox::before{
            @apply 
                h-[1px]
                w-8
                border-l-0
                border-t-2
                left-[77px];
            
        }
    }

}



.EventMeta{
    @apply
        w-5/16 
        flex 
        flex-col
        gap-4 
        pt-7;
    
    //resonspive
    @screen md{
        @apply
            w-full 
            grid
            grid-cols-3 
            grid-flow-col
            grid-rows-2
            gap-x-0;
        
        &>div{
            @apply
                py-4
                px-2
                border-t
                border-b-0
                border-t-gray-line;
        }

        h3{
            @apply text-24;
        }
    }
}